<template>
  <div>
    <master-component :config="config"></master-component>
  </div>
</template>


<script>
import * as dropdown from "./../js/dropdown-service";
import { UserRole, InputType, DialogSize } from "./../js/constants";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";

export default {
  data() {
    return {
      config: {},
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-customer",
        shortName: "ลูกค้า",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
  },

  computed: {
    urlSection() {
      let searchUrl = "/customer/search";
      let getUrl = "/customer";
      let addUrl = "/customer";
      let editUrl = "/customer";
      let removeUrl = "/customer";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MANAGEMENT],
        edit: [UserRole.ADMIN, UserRole.MANAGEMENT],
        remove: [UserRole.ADMIN],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "Name",
          },
          {
            name: "email",
            text: "Email",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "name",
              text: "ชื่อลูกค้า",
              type: InputType.text,
              rule: {
                required: true,
              },
            },
            {
              name: "email",
              text: "Email",
              type: InputType.text,
              rule: {
                //email: true
              },
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
  },
};
</script>
